import { useRouter } from "next/router";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { WishListAtom } from "~/services/wishlist";
import Button from "../Button";
import styles from "./WishButton.module.scss";
import HeartIcon from "~/assets/svgs/heart.svg";

const WishButton: FC = () => {
  const { wishlistItems } = useRecoilValue(WishListAtom.WishlistSelector);
  const router = useRouter();

  return (
    <div className="relative">
      {!!wishlistItems?.length && (
        <div className={styles.wish_badge}>{wishlistItems?.length}</div>
      )}
      <Button
        prefixIcon={<HeartIcon width={24} height={24} alt="heart" />}
        shape="square"
        type="borderless"
        onClick={() => router.push("/wishlist")}
        aria-label="Wishlist"
      />
    </div>
  );
};

export default WishButton;
