import { useState, useEffect } from 'react';

function useWindowDimensions() {
    const getWindowDimensions = () => ({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [windowDimensions, setWindowDimensions] = useState<{ width: number; height: number }>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        setWindowDimensions(getWindowDimensions());
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export default useWindowDimensions;
