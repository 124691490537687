const fs = require("fs");

export const isValidEmail = (email: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const isValidPhoneNumber = (number?: string) => {
  const phoneRegex = /([\+[0-9]|[0-9]|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;
  if (number) {
    return phoneRegex.test(number);
  } else return true;
};
