import axios from 'axios';

export const estimateShippingCart = async ({ address, cartId }: { address: any; cartId: any }) => {
    const serviceUrl = `/rest/V1/stripe/estimate-cart/${cartId}`;
    const payload = { address };
    try {
        const response = await axios.post(serviceUrl, payload);
        return response.data;
    } catch (error: any) {
        console.error('Error:', error.response?.data.message || error.message);
        throw error;
    }
};

export const applyShipping = async ({
    address,
    shippingId,
    cartId
}: {
    address: any;
    shippingId: any;
    cartId: any;
}) => {
    const serviceUrl = `/rest/V1/stripe/apply-shipping/${cartId}`;
    const payload = { address, shipping_id: shippingId };
    try {
        const response = await axios.post(serviceUrl, payload);
        return response.data;
    } catch (error: any) {
        console.error('Error:', error.response?.data.message || error.message);
        throw error;
    }
};

export const getApplePayParams = async ({ type = 'minicart', cartId }: { type?: string; cartId: any }) => {
    const serviceUrl = `/rest/V1/stripe/get-prapi-params/${cartId}`;
    const payload = { type };
    try {
        const response = await axios.post(serviceUrl, payload);
        return response.data;
    } catch (error: any) {
        console.error('Error:', error.response?.data.message || error.message);
        return error;
    }
};

export const getClientSecretFromResponse = (response: any) => {
    if (typeof response != 'string') {
        return null;
    }

    if (response.indexOf('Authentication Required: ') >= 0) {
        return response.substring('Authentication Required: '.length);
    }
    return null;
};

export const placeOrder = async ({
    cartId,
    payload
}: {
    cartId: any;
    payload: {
        result: any;
        location?: 'minicart';
    };
}) => {
    const serviceUrl = `/rest/V1/stripe/place-order/` + cartId;
    try {
        const response = await axios.post(serviceUrl, payload);
        return response;
    } catch (error: any) {
        console.error('Error:', error.response?.data.message || error.message);
        throw error;
    }
};
