import classNames from "classnames";
import { FC } from "react";
import styles from "./Avatar.module.scss";

interface AvatarProps {
  name: string;
  size?: 'xsmall' | "small" | "medium" ;
}

const Avatar: FC<AvatarProps> = ({ name, size = "small" }) => {
  const firstLetter = name?.charAt(0)?.toUpperCase();

  return (
    <div className={classNames(styles.avatar, styles[size])}>{firstLetter}</div>
  );
};

export default Avatar;
