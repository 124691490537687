import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { UserAtom } from '~/services/user';
import Avatar from '../Avatar';
import Button from '../Button';
import UserIcon from '~/assets/svgs/user.svg';
import { CartAtom } from '~/services/cart';
import { PATH } from '~/constants/enum';

const UserButton: FC = () => {
    const router = useRouter();
    const currentUser = useRecoilValue(UserAtom.currentUser);
    const Cart = useRecoilValue(CartAtom.cartSelector);

    // if (currentUser?.loading || Cart?.loading) {
    //     return <Avatar size="xsmall" name={currentUser?.firstname || currentUser?.lastname || '-'} />;
    // }
    return currentUser?.token ? (
        <Link href={PATH.ProfileInformation} className="inline-flex items-center">
            <Avatar size="xsmall" name={currentUser.firstname || currentUser.lastname || '-'} />
        </Link>
    ) : (
        <Button
            prefixIcon={<UserIcon width={24} height={24} alt="user" />}
            shape="square"
            type="borderless"
            onClick={() => {
                router.push(PATH.SignIn);
            }}
            aria-label="Sign in"
        ></Button>
    );
};

export default UserButton;
