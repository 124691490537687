import { atom } from "recoil";
import { Order } from "./order.type";
import { localStorageEffect } from "../storage";

const order = atom<Partial<Order>>({
  key: "order",
  default: undefined,
  effects: [localStorageEffect("order")],
});

const OrderAtom = {
  order,
};

export default OrderAtom;
