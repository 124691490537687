import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import ReactDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import Icon from '../Icon';
import styles from './Drawer.module.scss';

interface DrawerProps {
    open?: boolean;
    onClose?: () => void;
    direction?: 'left' | 'right' | 'top' | 'bottom';
    lockBackgroundScroll?: boolean;
    children?: React.ReactNode;
    duration?: number;
    overlayOpacity?: number;
    overlayColor?: String;
    enableOverlay?: boolean;
    style?: React.CSSProperties;
    zIndex?: number;
    size?: number | string;
    className?: string;
    customIdSuffix?: string;
    overlayClassName?: string;
    title?: ReactNode;
    width?: any;
    titleClassName?: string;
    iconClassName?: string;
    showTitle?: boolean;
}

const Drawer: FC<DrawerProps> = ({
    open = false,
    direction = 'right',
    title,
    children,
    className,
    width = 400,
    style,
    titleClassName,
    iconClassName,
    showTitle = true,
    ...props
}) => {
    return (
        <ReactDrawer
            zIndex={1000}
            lockBackgroundScroll
            {...props}
            style={{ ...style, width }}
            className={classNames(className, styles.drawer)}
            direction={direction}
            open={open}
        >
            {showTitle && (
                <div className={classNames('flex items-start justify-between gap-3', titleClassName)}>
                    {title}
                    <Icon
                        name="close"
                        className={`cursor-pointer ${iconClassName}`}
                        // color={Colors.GREY_100}
                        size={32}
                        onClick={props?.onClose}
                    />
                </div>
            )}
            {children}
        </ReactDrawer>
    );
};

export default Drawer;
