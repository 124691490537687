import { FC, useEffect, useMemo, useState } from 'react';

import Button from '../Button';
import Text from '../Text';

import Skeleton from 'react-loading-skeleton';
import Colors from '~/constants/colors';
import { CartItem } from '~/services/cart';
import { formatNumber } from '~/utils/number';
import Divider from '../Divider';
import QuickCartMiniItem from './Item/QuickCartMiniItem';
import { PATH } from '~/constants/enum';
import PaymentStripe from '../PaymentStripe';
import React from 'react';

interface QuickCartProps {
    items?: CartItem[];
    loading?: boolean;
}

const QuickCartMini: FC<QuickCartProps> = ({ items, loading }) => {
    const [idDeleting, setIdDeleting] = useState<string>();

    const subtotal =
        items
            ?.map((item) => {
                const finalPrice = item.selectedVariant?.product?.price_range?.minimum_price?.final_price?.value || 0;
                const quantity = item.quantity || 1;

                return finalPrice * quantity;
            })
            .reduce((sum, item) => sum + item, 0) || 0;

    const products = useMemo(() => {
        if (loading) return Array.from({ length: 2 }).map((_, i) => i) as any[];
        return items;
    }, [items, loading]);

    return (
        <div className="bg-white rounded-xl shadow-xl border">
            <div className="bg-secondary p-4 rounded-t-xl" style={{ background: Colors.PRIMARY_LIGHT }}>
                <Text type="title-5">
                    Cart ({items?.length} item{Number(items?.length) > 1 ? 's' : ''})
                </Text>
            </div>
            <div className="flex flex-col gap-6 w-[350px] max-h-[350px] overflow-auto p-4 pr-6 overscroll-contain">
                {products?.map((i, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            <QuickCartMiniItem
                                cartItem={i}
                                loading={loading}
                                setIdDeleting={setIdDeleting}
                                idDeleting={idDeleting}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
            <Divider />
            <div className="p-4">
                <div className="flex justify-between mb-2">
                    <Text type="title-4">Subtotal:</Text>
                    <Text type="title-4" className="text-primary">
                        {loading ? <Skeleton className="w-[80px]" /> : <>${formatNumber(subtotal, 2)}</>}
                    </Text>
                </div>
                <div className="flex justify-center flex-col gap-1">
                    {subtotal > 0 && (
                        <div className="w-full">
                            <PaymentStripe />
                        </div>
                    )}
                    <a href={PATH.Carts} className="w-full" onClick={(e) => loading && e.preventDefault()}>
                        <Button
                            className="w-full "
                            // variant="secondary"
                            type="outline"
                            variant="secondary"
                            disabled={loading}
                        >
                            View cart & check out
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default QuickCartMini;
