
export enum PaymentMethods {
  CARD = "card",
  PAYPAL = "paypal",
  STRIPE = "stripe_payments",
  AIRWALLEX = "airwallex_payments_card",
  PAYPAL_EXPRESS = "paypal_express",
  PAYPAL_EXPRESS_BML = "paypal_express_bml",
  CASH_ON_DELIVERY = "cashondelivery",
  PAYPAL_CREDIT = "paypal_credit",
}

export interface Payment {
  method: PaymentMethods;
  code: string;
}
