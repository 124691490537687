import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Bag from '~/assets/svgs/bag.svg';
import { PATH } from '~/constants/enum';
import { CartAtom } from '~/services/cart';
import Button from '../Button';
import QuickCartMini from '../QuickCartMini';
import styles from './CartButton.module.scss';
import useWindowDimensions from '~/hooks/useWindowDimensions';

const CartButton: FC = () => {
    const router = useRouter();
    const cartSelector = useRecoilValue(CartAtom.cartSelector);
    const showMiniCart = cartSelector?.loading || !isEmpty(cartSelector?.items);
    const { width } = useWindowDimensions();

    const onOverlay = (type: 'append' | 'remove') => {
        if (width <= 1024) return;
        if (!showMiniCart || router.route === '/carts') return;

        if (type === 'append') {
            document.body.classList.add('show-overlay');
        }

        if (type === 'remove') {
            document.body.classList.remove('show-overlay');
        }
    };

    useEffect(() => {
        if (!showMiniCart) {
            document.body.classList.remove('show-overlay');
        }
    }, [showMiniCart]);

    return (
        <div
            className={classNames(styles.cart_button)}
            onMouseOver={() => onOverlay('append')}
            onMouseLeave={() => onOverlay('remove')}
        >
            {!!cartSelector?.items?.length && <div className={styles.cart_badge}>{cartSelector?.items?.length}</div>}
            <a href={PATH.Carts} onClick={(e) => router.route === '/carts' && e.preventDefault()}>
                <Button
                    prefixIcon={<Bag width={24} height={24} alt="bag" />}
                    shape="square"
                    type="borderless"
                    // onClick={() => setCart({ ...cart, open: true })}
                    aria-label="Shopping cart"
                />
            </a>
            {router.route !== '/carts' && showMiniCart && (
                <div className={classNames(styles.quick_cart_mini, 'mt-2 transition-all hidden lg:block')}>
                    <QuickCartMini items={cartSelector?.items} loading={cartSelector?.loading} />
                </div>
            )}
        </div>
    );
};

export default CartButton;
