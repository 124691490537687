import { FC, Fragment, useEffect, useState } from 'react'
import Divider from '~/components/Divider'
import Drawer from '~/components/Drawer'
import Icon from '~/components/Icon'
import Text from '~/components/Text'
import Colors from '~/constants/colors'
import { CmsBlockProductList, MegaMenuType, PATH } from '~/constants/enum'
import { parseVarFormCmsBlock } from '~/utils/converters'
import { MenuTransformProps } from '../interfaces'
import styles from './DrawerMenu.module.scss'
import MenuItem from './MenuItem'
import { isEmpty } from 'lodash'

type DrawerMenuProps = {
    menuItems?: MenuTransformProps
    onCloseDrawer?: () => void
    showDivider?: boolean
}

export const renderLink = (item?: MenuTransformProps, onClose?: () => void) => {
    const { title, url, type } = item || {}
    if (!title) return null

    const href = type === MegaMenuType.Category ? PATH.CategoryDetail.replace("[slug]", url) : url ? url : ""
    return (
        <>
            {
                href ?
                    <a
                        href={href}
                        onClick={(e) => {
                            e.stopPropagation()
                            onClose?.()
                        }}
                    >
                        {title}
                    </a>
                    :
                    title
            }
        </>
    )
}

const renderCmsBlock = (menu?: MenuTransformProps) => {
    const { content } = menu || {}
    const { typeProduct } = parseVarFormCmsBlock(content) || {}

    const notRender = [
        CmsBlockProductList.BestSeller,
        CmsBlockProductList.Poppular,
        CmsBlockProductList.Category,
        CmsBlockProductList.Trending,
        CmsBlockProductList.Collection
    ].includes(typeProduct as CmsBlockProductList)

    if (notRender) return null

    return (
        <div dangerouslySetInnerHTML={{ __html: content }} className='p-2 flex justify-between' />
    )
}

export const renderContent = (item?: MenuTransformProps, onCloseDrawer?: () => void, minusSpace?: number) => {
    const { type, children = [] } = item || {}

    switch (type) {
        case MegaMenuType.CmsBlock:
            return renderCmsBlock(item)
        default:
            return (
                <Fragment>
                    {children.map((item, index: number) => {
                        return (
                            <MenuItem
                                key={index}
                                item={item as MenuTransformProps}
                                onCloseDrawer={onCloseDrawer}
                                minusSpace={minusSpace}
                            />
                        )
                    })}
                </Fragment>
            )
    }
}

export const renderDrawerMenu = (menuItems: MenuTransformProps, onCloseDrawer?: () => void) => {
    const { type } = menuItems || {}

    switch (type) {
        case MegaMenuType.CmsBlock:
        case MegaMenuType.Wrapper:
        case MegaMenuType.CustomUrl:
        case MegaMenuType.CmsPage:
        case MegaMenuType.Category:
            return (
                <DrawerMenu
                    menuItems={menuItems}
                    onCloseDrawer={onCloseDrawer}
                />
            )
        default:
            return null
    }
}

const DrawerMenu: FC<DrawerMenuProps> = ({ menuItems, onCloseDrawer, showDivider }) => {
    const [open, setOpen] = useState(false)
    const { title, children } = menuItems || {}

    const onClose = () => {
        setOpen(false)
    }

    const onOpen = () => {
        if (!isEmpty(children)) {
            setOpen(true)
        }
    }

    const minusSpace = (-59 - 56 - 86)

    return (
        <div>
            <div onClick={onOpen} className='pb-3'>
                <div className='flex justify-between items-center'>
                    <Text type="title-5">
                        {
                            renderLink(
                                menuItems,
                                () => {
                                    onCloseDrawer?.()
                                    onClose()
                                })
                        }
                    </Text>
                    {!isEmpty(children) &&
                        <Icon name="arrow-right-1" size={20} />
                    }
                </div>
            </div>
            {showDivider &&
                <Divider dividerColor={Colors.GREY_100} />
            }
            <Drawer
                open={open}
                onClose={() => {
                    onCloseDrawer?.()
                    onClose()
                }}
                className={styles.sub_mobile_header}
                titleClassName="items-center"
                overlayClassName={styles.sub_drawer_overlay}
                showTitle={false}
            >
                <div>
                    <div onClick={onClose}>
                        <Divider dividerColor={Colors.GREY_100} />
                        <div className='flex gap-2 items-center py-3'>
                            <Icon name="arrow-left" size={20} className='ps-2' />
                            <span className='text-gray-300'>
                                {"|"}
                            </span>
                            <Text type="title-5">{title}</Text>
                        </div>
                        <Divider dividerColor={Colors.GREY_100} />
                    </div>
                    <div className="overflow-auto" style={{ height: `calc(100vh + ${minusSpace}px)` }}>
                        {renderContent(menuItems, onCloseDrawer, minusSpace)}
                    </div>
                </div>
            </Drawer >
        </div >
    )
}

export default DrawerMenu