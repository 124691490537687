import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { debounce, get, isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import Container from '~/components/Container';
import Icon from '~/components/Icon';
import Input from '~/components/Input';
import Tag from '~/components/Tag';
import Text from '~/components/Text';
import Colors from '~/constants/colors';
import { AppContext } from '~/contexts/app.context';
import { SEARCH_KEYWORD_SUGGESTION, SEARCH_SUGGESTION } from '~/data/search/gql';
import { UseGetSearchSuggestions } from '~/data/search/interface';
import useClickOutside from '~/hooks/useClickOutside';
import { recentKeywordState } from '~/services/recent-search/recent-search.atom';
import styles from './SearchFilter.module.scss';
import { matchEmphasize } from '~/utils/transform';

interface SearchFilterProps {
    recentKeywords?: string[];
    trendingKeywords?: string[];
    openSearch?: boolean;
    onCloseSearch?: () => void;
}

const SearchFilter: FC<SearchFilterProps> = (props) => {
    const { recentKeywords = [], trendingKeywords = [], openSearch, onCloseSearch } = props;
    const router = useRouter();
    const searchFilterRef = useRef(null);
    const setRecoilRecentKeyword = useSetRecoilState(recentKeywordState);

    const [searchValue, setSearchValue] = useState('');
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [tempValue, setTempValue] = useState('');

    const [debounceSearchValue, setDebounceSearchValue] = useState<string>('');

    const debounceSearchHandler = useCallback(
        debounce((searchValue: string) => {
            setDebounceSearchValue(searchValue);
        }, 500),
        []
    );
    const { data: searchSuggestionV2 } = useQuery(SEARCH_KEYWORD_SUGGESTION, {
        variables: {
            keyword: debounceSearchValue
        },
        onError: ({ message }) => console.error(message, 'SEARCH_SUGGESTION'),
        skip: isEmpty(debounceSearchValue)
    });
    // Search Suggestions
    const { data: searchSuggestionResponse, loading: searchSuggestionLoading } = useQuery<UseGetSearchSuggestions>(
        SEARCH_SUGGESTION,
        {
            variables: {
                keyword: debounceSearchValue
            },
            onError: ({ message }) => console.error(message, 'SEARCH_SUGGESTION'),
            skip: isEmpty(debounceSearchValue)
        }
    );

    const searchSuggestions = useMemo(() => {
        return [
            // ...get(searchSuggestionV2, 'SearchSuggestion', []),
            ...get(searchSuggestionResponse, 'SearchSuggestion', [])
        ].filter((item, index, self) => self.indexOf(item) === index);
    }, [searchSuggestionV2, searchSuggestionLoading, searchSuggestionResponse, searchSuggestionLoading]);

    // Products

    const handleSearchProduct = (value: string) => {
        if (isEmpty(value)) return;
        setRecoilRecentKeyword?.((prev) => {
            if (isEmpty(value) || prev.includes(value)) {
                return prev;
            }
            return [value, ...prev];
        });
        handleCloseSearch();

        router.push({
            pathname: '/search',
            query: {
                keyword: value
            }
        });
        // setOpenSearch(false);
    };

    const handleKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const suggestions = get(searchSuggestionV2, 'SearchKeywordSuggestion', []);
        if (e.key === 'ArrowDown') {
            setFocusedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
            setTempValue(suggestions[(focusedIndex + 1) % suggestions.length]);
        } else if (e.key === 'ArrowUp') {
            setFocusedIndex((prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length);
            setTempValue(suggestions[(focusedIndex - 1 + suggestions.length) % suggestions.length]);
        } else if (e.key === 'Enter' && focusedIndex >= 0) {
            handleSearchProduct(suggestions[focusedIndex]);
            setSearchValue(suggestions[focusedIndex]);
            setFocusedIndex(-1);
            setTempValue('');
        } else if (e.key === 'Enter') {
            handleSearchProduct(searchValue);
        }

        // if (e.key === 'Enter') {
        //     handleSearchProduct(searchValue);
        // }
    };

    const handleCloseSearch = () => {
        setSearchValue('');
        setDebounceSearchValue('');
        onCloseSearch?.();
    };

    const handleChangeSearchValue = (value: string) => {
        setSearchValue(value);
        setFocusedIndex(-1);
        setTempValue('');
        debounceSearchHandler(value);
    };

    useClickOutside(searchFilterRef, handleCloseSearch);

    useEffect(() => {
        if (openSearch) {
            // Create overlay element
            const overlay = document.createElement('div');
            overlay.className = 'fixed inset-0 bg-black bg-opacity-50 z-10';
            overlay.onclick = handleCloseSearch;

            // Append overlay to body
            document.body.appendChild(overlay);

            // Cleanup on unmount or when `isOpen` changes
            return () => {
                document.body.removeChild(overlay);
            };
        }
    }, [openSearch, handleCloseSearch]);

    return (
        <Container>
            <div
                className={classNames(styles.search_filter, 'dropdown-animation', {
                    'dropdown-animation-show': openSearch
                })}
                ref={searchFilterRef}
            >
                <Input
                    placeholder="Search your sneaker..."
                    value={tempValue || searchValue}
                    onChange={(e) => handleChangeSearchValue(e.target.value)}
                    onKeyDown={handleKeyDownSearch}
                    onClearText={() => handleChangeSearchValue('')}
                    suffix={
                        searchValue && (
                            <Icon
                                name="search-normal-1"
                                color={Colors.GREY_400}
                                size={20}
                                onKeyDown={handleKeyDownSearch}
                                className="cursor-pointer"
                                onClick={() => handleSearchProduct(searchValue)}
                            />
                        )
                    }
                />

                {
                    <div className="flex flex-col transition-all transform ease-in-out duration-200 ">
                        {get(searchSuggestionV2, 'SearchKeywordSuggestion', [])?.map(
                            (keyword: string, index: number) => (
                                <div
                                    className={classNames('hover:bg-[#f1f1f1] p-2 rounded-lg cursor-pointer ', {
                                        ['bg-[#f1f1f1]']: focusedIndex === index
                                    })}
                                    key={index}
                                    onClick={() => handleSearchProduct(keyword)}
                                    dangerouslySetInnerHTML={{
                                        __html: matchEmphasize({ name: keyword, search: debounceSearchValue }) || ''
                                    }}
                                ></div>
                            )
                        )}
                    </div>
                }
                {debounceSearchValue ? (
                    <Fragment>
                        {!isEmpty(searchSuggestions) && (
                            <div className={styles.item}>
                                <Text type="title-2" weight={700}>
                                    Search Suggestion
                                </Text>
                                <div className={styles.tags}>
                                    {searchSuggestions.map((keyword, index) => (
                                        <Tag
                                            key={index}
                                            name={keyword}
                                            onClick={() => handleSearchProduct(keyword)}
                                            matchValue={debounceSearchValue}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    // )
                    <Fragment>
                        {recentKeywords.length ? (
                            <div className={styles.item}>
                                <Text type="title-2" weight={700}>
                                    Recent Search
                                </Text>
                                <div className={styles.tags}>
                                    {recentKeywords?.map((keyword) => (
                                        <Tag
                                            key={keyword}
                                            name={keyword}
                                            onClick={() => handleSearchProduct(keyword)}
                                            onDelete={() =>
                                                setRecoilRecentKeyword?.((prev) =>
                                                    prev.filter((item) => item !== keyword)
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                )}
            </div>
        </Container>
    );
};

export default SearchFilter;
