import { useQuery } from "@apollo/client";
import { QUERY_GET_MEGA_MENU_GQL } from "./gql";
import { NodesResponse } from "../home/interface";
import { clone, cloneDeep } from "lodash";

type Props = {
  identifiers: string;
};

export const useMegaMenu = ({ identifiers }: Props) => {
  const buildTree = (nodes: NodesResponse[]) => {
    const tree = cloneDeep(nodes);
    const map = new Map();
    tree.forEach((node) => map.set(node.node_id, node));
    tree.forEach((node) => {
      const parent = map.get(node.parent_id);
      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.push(node);
      }
    });
    return tree.filter((node) => node.level === 0);
  };

  const { data, loading, error } = useQuery(QUERY_GET_MEGA_MENU_GQL, {
    fetchPolicy: "network-only",
    variables: {
      identifiers: identifiers,
    },
    context: { fetchOptions: { method: "GET" } },
  });
  const nodes = buildTree(data?.getMenuByIdentifier?.nodes || []);
  return { nodes, loading, error };
};
