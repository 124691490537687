/* eslint-disable @next/next/no-img-element */
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { v4 } from "uuid";
import Button from "~/components/Button";
import Container from "~/components/Container";
import Text from "~/components/Text";
import WishButton from "~/components/WishButton";
import { AppContext } from "~/contexts/app.context";
import storeConfigAtom from "~/services/store-config/store-config.atom";
import styles from "./Header.module.scss";
import { getTheme } from "~/utils/theme";
import { megaMenuAtom } from "~/services/megaMenu";
import classNames from "classnames";
import { MegaMenuType } from "~/constants/enum";
import MenuDropdown from "~/components/Menu/MenuDropdown";
import Divider from "~/components/Divider";
import stylesMenuDropDow from "~/components/Items/MenuItem/MenuItem.module.scss";
import SearchFilter from "../SearchFilter";
import { recentKeywordState } from "~/services/recent-search/recent-search.atom";

const MobileHeader = dynamic(() => import("./MobileHeader"), { ssr: false });
const CartButton = dynamic(() => import("~/components/CartButton"), {
  ssr: false,
});
const UserButton = dynamic(() => import("~/components/UserButton"), {
  ssr: false,
});
const TopHeader = dynamic(() => import("~/components/TopHeader"), {
  ssr: false,
});

const Navigation = dynamic(async () => {
  try {
    return await (
      require(`~/theme/${getTheme()}/sections/Navigation`)
    );
  } catch (error) {
    return await require(`~/sections/Navigation`);
  }
})


interface HeaderProps {
  menuItems?: { id: string; label: string; url: string }[];
  banner?: ReactNode; // Pass null to hide the banner
  isUseLayout?: boolean
}

const SimpleHeader: FC<HeaderProps> = ({
  menuItems = [],
  banner,
  isUseLayout = true
}) => {
  const refHeader = useRef<any>()

  const [openMenu, setOpenMenu] = useState(false);
  const storeConfig = useRecoilValue(storeConfigAtom.store)


  


  return (
    <>
      {
        <TopHeader />
      }
      <div className={styles.header} ref={refHeader}>
        <Container className="flex items-center  py-2 justify-around relative">
          <div className="flex items-center gap-1">
            <Link href="/" className="h-10">
              {
                storeConfig?.frontend_config_logo &&
                <img
                  src={storeConfig?.frontend_config_logo || ''}
                  alt="Logo"
                  className="h-full object-contain object-left"
                  width={"auto"}
                  height={40}
                />
              }
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SimpleHeader;
