export const getShippingAddressFrom = (prapiShippingAddress: any) => {
  if (!prapiShippingAddress) return null;
  if (
    prapiShippingAddress.city.length === 0 &&
    prapiShippingAddress.region.length > 0
  )
    prapiShippingAddress.city = prapiShippingAddress.region;
  if (prapiShippingAddress.recipient.length > 0)
    prapiShippingAddress.name = prapiShippingAddress.recipient;

  return prapiShippingAddress;
};
