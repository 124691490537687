import { isEmpty } from "lodash"
import { useState } from "react"
import Divider from "~/components/Divider"
import Drawer from "~/components/Drawer"
import Icon from "~/components/Icon"
import Text from "~/components/Text"
import Colors from "~/constants/colors"
import { MegaMenuType } from "~/constants/enum"
import { renderContent, renderLink } from ".."
import { MenuTransformProps } from "../../interfaces"
import styles from "./Menu.module.scss"
import classNames from "classnames"

export type MenuItemProps = {
    item: MenuTransformProps
    onCloseDrawer?: () => void
    minusSpace?: number
}

const MenuItem = ({ item, onCloseDrawer, minusSpace = 0 }: MenuItemProps) => {
    const { title, type, children = [] } = item || {}
    const nextDrawer = !isEmpty(children)
    const [open, setOpen] = useState(false)

    const onClose = () => {
        setOpen(false)
    }

    const onOpen = () => {
        if (nextDrawer) {
            setOpen(true)
        }
    }

    return (
        <div>
            <div
                className={classNames(`py-2 px-2 flex justify-between items-center`, !nextDrawer && 'mb-[4.5px]')}
                onClick={onOpen}
            >
                <Text
                    htmlTag="span"
                    type="label-1"
                >
                    {
                        renderLink(
                            item,
                            () => {
                                onCloseDrawer?.()
                                onClose()
                            })
                    }
                </Text>
                {
                    nextDrawer &&
                    <div className='flex items-center'>
                        <Icon name="arrow-right-1" size={20} />
                    </div>
                }
            </div>
            {
                nextDrawer && (
                    <Drawer
                        open={open}
                        onClose={() => {
                            onCloseDrawer?.()
                            onClose()
                        }}
                        className={styles.sub_item_mobile_header}
                        titleClassName="items-center"
                        overlayClassName={styles.sub_item_drawer_overlay}
                        showTitle={false}
                    >
                        <div>
                            <div onClick={onClose}>
                                <Divider dividerColor={Colors.GREY_100} />
                                <div className='flex gap-2 items-center py-3'>
                                    <Icon name="arrow-left" size={20} className='ps-2' />
                                    <span className='text-gray-300'>
                                        {"|"}
                                    </span>
                                    <Text type="title-5">{title}</Text>
                                </div>
                                <Divider dividerColor={Colors.GREY_100} />
                            </div>
                            <div
                                className="overflow-auto"
                                style={{ height: `calc(100vh + ${minusSpace}px)` }} // 59px: header, 56px: drawer header, 38px: drawer footer
                            >
                                {renderContent(item)}
                            </div>
                        </div>
                    </Drawer>
                )
            }
        </div>
    )
}

export default MenuItem;