import { atom, selector } from 'recoil';
import { localStorageEffect } from '../storage';

export const recentKeywordState = atom<string[]>({
    key: 'recentKeywords',
    default: [],
    effects: [localStorageEffect('recentKeywords')]
});

export const recentSearchSelector = selector({
    key: 'recentSearchSelector',
    get: ({ get }) => {
        const value = get(recentKeywordState);
        return value;
    }
});
