import { useEffect, useMemo, useState } from "react";
import { getApplePayParams } from "./services";
import { useStripe } from "@stripe/react-stripe-js";
import { StripePaymentRequestButtonElementOptions } from "@stripe/stripe-js";

export const useOptions = (paymentRequest: any) => {
  const options: StripePaymentRequestButtonElementOptions = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "40px",
          type: "default",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};

export const usePaymentRequest = ({
  cartId,
  onPaymentMethod,
  onShippingAddressChange,
  onShippingOptionChange,
}: any) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  const getOptions = async () => {
    let params = await getApplePayParams({
      cartId: cartId,
    });
    params = JSON.parse(params);
    return params;
  };
  useEffect(() => {
    if (stripe && paymentRequest === null && !!cartId) {
      getOptions().then((res: any) => {
        const pr: any = stripe.paymentRequest(res);
        setPaymentRequest(pr);
      });
    }
  }, [stripe, cartId, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then((res: any) => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
      paymentRequest.on("shippingaddresschange", onShippingAddressChange);
      paymentRequest.on("shippingoptionchange", onShippingOptionChange);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
        paymentRequest.on("shippingaddresschange", onShippingAddressChange);
        paymentRequest.on("shippingoptionchange", onShippingOptionChange);
      }
    };
  }, [paymentRequest]);

  return canMakePayment ? paymentRequest : null;
};
