import {
  ExpressCheckoutElement,
  PaymentRequestButtonElementProps,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  StripeExpressCheckoutElementOptions,
} from "@stripe/stripe-js";
import ConnectStripe from "~/components/ConnectStripe";
import Divider from "~/components/Divider";
import { useEffect, useRef, useState } from "react";
import {
  applyShipping,
  estimateShippingCart,
  getApplePayParams,
  getClientSecretFromResponse,
  placeOrder,
} from "../services";
import { useRecoilValue, useSetRecoilState } from "recoil";
import cartAtom from "~/services/cart/cart.atom";
import storeConfigAtom from "~/services/store-config/store-config.atom";
import { getShippingAddressFrom } from "./utils";
import { toast } from "react-toastify";
import { CartAtom } from "~/services/cart";
import { get } from "lodash";
import { PaymentMethods } from "~/services/payment";
import OrderAtom from "~/services/order/order.atom";
import { PATH } from "~/constants/enum";
import router from "next/router";
import { resolve } from "path";

export type Props = {
  onError?: (error: { message: string }) => void;
  text?: string;
  loading?: boolean;
  onClick?: (event: any, paymentRequest: any) => void;
} & any;

const ExpressPaymentForm = ({ onClick, ...props }: Props) => {
  const setCart = useSetRecoilState(CartAtom.cart);
  const cart = useRecoilValue(CartAtom.cartSelector);
  const setOrder = useSetRecoilState(OrderAtom.order);
  const [loadingState, setLoadingState] = useState(true);

  const stripe: any = useStripe();
  const { cartId, shipping_methods } = useRecoilValue(cartAtom.cartSelector);

  const stripeCheckoutForm = useRef<any>();
  const [stripePromise, setStripePromise] = useState<any>();

  let newWindow: any;
  const onShippingAddressChange = async (event: any) => {
    const shippingAddress = getShippingAddressFrom(event.shippingAddress);
    newWindow = shippingAddress;
    try {
      const response = await estimateShippingCart({
        address: shippingAddress,
        cartId,
      });
      const shippingOptions = JSON.parse(response).results;

      if (shippingOptions.length < 1) {
        event.updateWith({ status: "invalid_shipping_address" });
        return;
      }

      let shippingMethod = null;
      if (shippingOptions.length > 0) {
        const shippingOption = shippingOptions[0];
        shippingMethod = shippingOption.hasOwnProperty("id")
          ? shippingOption.id
          : null;
      }

      const applyShippingResponse = await applyShipping({
        address: shippingAddress,
        shippingId: shippingMethod,
        cartId,
      });
      const result = {
        status: "success",
        shippingOptions: shippingOptions,
        ...JSON.parse(applyShippingResponse)?.results,
      };
      event.updateWith(result);
    } catch (error) {
      console.error("Error:", error);
      event.updateWith({ status: "fail" });
    }
  };
  const onShippingOptionChange = async (event: any) => {
    try {
      const shippingMethod = event.shippingOption?.id || null;
      if (!newWindow) {
        event.updateWith({ status: "invalid_shipping_address" });
        return;
      }

      const applyShippingResponse = await applyShipping({
        address: newWindow,
        shippingId: shippingMethod,
        cartId,
      });
      const result = JSON.parse(applyShippingResponse);

      // Ensure that a 'status' is provided in the response
      if (result && result.results) {
        event.updateWith({ status: "success", ...result.results });
      } else {
        event.updateWith({ status: "fail" });
      }
    } catch (error) {
      console.error("Error:", error);
      event.updateWith({ status: "fail" });
    }
  };
  const onPaymentMethodChange = async (result: any) => {
    const payload: any = {
      result: result,
      location: "minicart",
    };
    try {
      const response = await placeOrder({
        cartId,
        payload,
      });
      let data = response.data;

      // Nếu response là chuỗi, cố gắng parse thành JSON
      if (typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (e) {
          throw new Error("Invalid JSON response: " + data); // Đảm bảo lỗi có cấu trúc
        }
      }

      // redirect to success page or handle success
      // closePaysheet
      result.complete("success");
      handleOnSuccess(data[0]);
    } catch (error: any) {
      // Kiểm tra lỗi từ server response
      if (error.response) {
        const response = error.response.data;

        try {
          // Lấy clientSecret từ response.message
          const clientSecret = getClientSecretFromResponse(response.message);
          if (clientSecret) {
            // Xác thực Stripe
            const { error } = await stripe?.confirmCardPayment(clientSecret);
            // Nếu thành công, gọi lại hàm placeOrder để đặt hàng lại
            return await onPaymentMethodChange(result);
          } else {
            throw new Error(error);
          }
        } catch (e) {
          // Hiển thị hoặc log lỗi với cấu trúc rõ ràng
          console.error("Payment processing error:", error.response.data);

          // throw new Error("Payment processing error: " + error.response.data);
          toast.error(
            "Payment processing error: " +
              get(error, "response.data.message", "")
          );
        }
      } else {
        // Xử lý các lỗi khác (network, etc.)
        console.error("Network error:", error.message);
        throw new Error("Network error: " + error.message);
      }
    }
  };

  const handleOnSuccess = (placeholderResponse: any) => {
    toast.success("Congratulation, your order is completed");
    setCart((prevCart) => ({
      ...prevCart,
      statusPlaceOrder: "success",
      selected_payment_method: {
        title: "Pay by Card",
        code: PaymentMethods.STRIPE,
      },
    }));
    setOrder({
      ...cart,
      order_number: placeholderResponse.order_number,
      selected_payment_method: {
        title: "Pay by Card",
        code: PaymentMethods.STRIPE,
      },
      referer: PATH.Checkout,
    });
    router.push("/thank-you");
  };

  const handleOnClick = ({ resolve }: any) => {
    const options = {};

    resolve(options);
  };

  return (
    <>
      {loadingState && (
        <div className="w-full  h-[40px] flex items-center justify-center ">
          ...loading
        </div>
      )}
      <ExpressCheckoutElement
        {...props}
        onConfirm={onPaymentMethodChange}
        onShippingRateChange={onShippingOptionChange}
        onShippingAddressChange={onShippingAddressChange}
        onReady={() => {
          setLoadingState(false);
        }}
        onLoadError={() => {
          setLoadingState(false);
        }}
        onClick={handleOnClick}
      />
    </>
  );
};

export default ExpressPaymentForm;
