import classNames from 'classnames';
import {
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import Colors from '~/constants/colors';
import Icon from '../Icon';
import Text from '../Text';
import styles from './Input.module.scss';
import { isValidEmail } from '~/utils/validators';
import { trackIdentification } from '~/services/tracking';

interface InputProps
    extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'prefix'> {
    label?: string;
    error?: string | false | ReactNode;
    suffix?: ReactNode;
    hint?: string;
    prefix?: ReactNode;
    showCount?: boolean;
    inputClassName?: string;
    variant?: 'default' | 'transparent';
    required?: boolean;
    onClearText?: () => void;
}

const Input: FC<InputProps> = ({
    label,
    className,
    error,
    suffix,
    prefix,
    hint,
    showCount,
    inputClassName,
    variant = 'default',
    required,
    onClearText,
    value,
    defaultValue,
    autoFocus = false,
    type,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const contentLength = value?.toString()?.length || 0;

    const inputType = useMemo(() => {
        if (type !== 'password') {
            return type;
        }

        if (showPassword) {
            return 'text';
        }

        return type;
    }, [showPassword, type]);

    const callbackRef = useCallback(
        (inputElement: any) => {
            if (inputElement && autoFocus) {
                setTimeout(() => inputElement.focus(), 5);
            }
        },
        [autoFocus]
    );

    const previousEmail = useRef<string>('');
    useEffect(() => {
        if (previousEmail.current === value) return;
        if (!isValidEmail(value as string)) return;

        trackIdentification({
            email: value as string
        });
        if (typeof window !== undefined) {
            const _learnq = window ? window['_learnq'] : undefined;
            if (!!value && !!_learnq && !!_learnq.isIdentified && !_learnq.isIdentified()) {
                _learnq.push([
                    'identify',
                    {
                        $email: value
                    }
                ]);
            }
        }
    }, [value]);

    return (
        <div
            className={classNames(styles.input_container, styles[variant], className, {
                [styles.disabled]: rest.disabled
            })}
        >
            {label && (
                <label
                    className={classNames(styles.input_label, {
                        [styles.required]: required
                    })}
                >
                    {label}
                </label>
            )}
            <div
                className={classNames(styles.input_field, {
                    [styles.has_error]: !!error
                })}
            >
                {prefix && <div className={styles.prefix}>{prefix}</div>}
                <input
                    ref={callbackRef}
                    autoFocus={autoFocus}
                    className={classNames(styles.input, inputClassName)}
                    defaultValue={defaultValue}
                    value={value}
                    autoComplete="off"
                    type={inputType}
                    {...rest}
                />

                {type === 'password' && (
                    <Icon
                        className={classNames(styles.suffix_icon, 'cursor-pointer')}
                        name={showPassword ? 'eye' : 'eye-slash'}
                        size={20}
                        color={Colors.GREY_900}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                )}

                {/* Update lại color sau khi được merge PR */}
                {showCount && (
                    <Text className={classNames(styles.counter, 'ml-2 mr-3')} type="body-3">
                        {contentLength}
                        {rest.maxLength && `/${rest.maxLength}`}
                    </Text>
                )}
                {onClearText && (defaultValue || value) && (
                    <Icon
                        name="close"
                        color={Colors.GREY_400}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClearText();
                        }}
                        className={styles.clear_icon}
                    />
                )}
                {suffix && <div className={styles.suffix_icon}>{suffix}</div>}
            </div>

            {hint && <span className={styles.hint}>{hint}</span>}
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
};

export default Input;
